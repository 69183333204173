import React, { useState } from "react";
import { useQuery } from "react-query";

import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";

import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Panel from "../../components/panels/Panel";
import Table from "../../components/Table";
import useFetchData from "../../hooks/useFetchData";

const TableWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  // max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  // max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const DailyMeters = () => {
  const { getAccessTokenSilently } = useAuth0();

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [selectedLocation, setSelectedLocation] = useState(34);

  const [years] = useFetchData("report-available-years-lookup", [], true);
  const [locations] = useFetchData(
    "report-meters-available-locations-lookup",
    [],
    true
  );

  const { data, isFetching, error } = useQuery(
    ["report-daily-meters-crosstab", selectedYear, selectedLocation],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-daily-meters-crosstab/${selectedYear}/${selectedLocation}`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Day",
      field: "d_day",
    },
    {
      title: "Oct",
      field: "oct",
      render: (row) =>
        row.oct !== null ? Number(row.oct).toLocaleString() : "",
    },
    {
      title: "Nov",
      field: "nov",
      render: (row) =>
        row.nov !== null ? Number(row.nov).toLocaleString() : "",
    },
    {
      title: "Dec",
      field: "dec",
      render: (row) =>
        row.dec !== null ? Number(row.dec).toLocaleString() : "",
    },
    {
      title: "Jan",
      field: "jan",
      render: (row) =>
        row.jan !== null ? Number(row.jan).toLocaleString() : "",
    },
    {
      title: "Feb",
      field: "feb",
      render: (row) =>
        row.feb !== null ? Number(row.feb).toLocaleString() : "",
    },
    {
      title: "Mar",
      field: "mar",
      render: (row) =>
        row.mar !== null ? Number(row.mar).toLocaleString() : "",
    },
    {
      title: "Apr",
      field: "apr",
      render: (row) =>
        row.apr !== null ? Number(row.apr).toLocaleString() : "",
    },
    {
      title: "May",
      field: "may",
      render: (row) =>
        row.may !== null ? Number(row.may).toLocaleString() : "",
    },
    {
      title: "Jun",
      field: "jun",
      render: (row) =>
        row.jun !== null ? Number(row.jun).toLocaleString() : "",
    },
    {
      title: "Jul",
      field: "jul",
      render: (row) =>
        row.jul !== null ? Number(row.jul).toLocaleString() : "",
    },
    {
      title: "Aug",
      field: "aug",
      render: (row) =>
        row.aug !== null ? Number(row.aug).toLocaleString() : "",
    },
    {
      title: "Sep",
      field: "sep",
      render: (row) =>
        row.sep !== null ? Number(row.sep).toLocaleString() : "",
    },
  ];

  return (
    <>
      <Helmet title="Daily Meters" />
      <Typography variant="h3" gutterBottom display="inline">
        Daily Meters Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Daily Meters</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    toolbar={
                      <>
                        {Object.values(years).length > 0 && (
                          <FormControl
                            size="small"
                            variant="outlined"
                            style={{ width: "120px", marginRight: "8px" }}
                          >
                            <InputLabel id="year-select">Year</InputLabel>
                            <Select
                              label="Year"
                              name="years"
                              value={selectedYear}
                              onChange={(event) =>
                                setSelectedYear(event.target.value)
                              }
                            >
                              {years.map((year) => (
                                <MenuItem
                                  key={year.report_year}
                                  value={year.report_year}
                                >
                                  {year.report_year}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {Object.values(locations).length > 0 && (
                          <FormControl
                            size="small"
                            variant="outlined"
                            style={{ width: "200px", marginRight: "8px" }}
                          >
                            <InputLabel id="location-select">
                              Location
                            </InputLabel>
                            <Select
                              label="Location"
                              name="location"
                              value={selectedLocation}
                              onChange={(event) =>
                                setSelectedLocation(event.target.value)
                              }
                            >
                              {locations.map((year) => (
                                <MenuItem
                                  key={year.location_ndx}
                                  value={year.location_ndx}
                                >
                                  {year.location_desc}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </>
                    }
                    options={{ filtering: false }}
                    label={`Daily Meters Report for ${
                      locations?.find(
                        (location) => location.location_ndx === selectedLocation
                      )?.location_desc ?? ""
                    } in ${selectedYear}`}
                    isLoading={isFetching}
                    columns={tabColumns}
                    data={data}
                    height="1200px"
                    pageSize={60}
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default DailyMeters;
