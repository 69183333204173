import React, { useRef, useState } from "react";
import { useQuery } from "react-query";

import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  lighten,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";

import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Panel from "../../components/panels/Panel";
import Table from "../../components/Table";
import useFetchData from "../../hooks/useFetchData";
import { lineColors } from "../../utils";
import SaveGraphButton from "../../components/graphs/SaveGraphButton";
import PrintGraphButton from "../../components/graphs/PrintGraphButton";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Monthly from "../../components/graphs/HistoricalProductionCharts/Monthly";

const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;

const TimeseriesContainerSmall = styled.div`
  height: 200px;
  // overflow-y: auto;
  width: 100%;
`;

const GraphWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100% - 46px);
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  // max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  // max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const MonthlyHistoricalProduction = () => {
  const { getAccessTokenSilently } = useAuth0();
  const saveRef = useRef(null);

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [years] = useFetchData("report-available-years-lookup", [], true);

  const months = [
    "oct",
    "nov",
    "dec",
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
  ];

  const monthlyLabels = [
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
  ];

  const [monthlyGraphData, setMonthlyGraphData] = useState();
  const [annualGraphData, setAnnualGraphData] = useState();
  const [totalGraphData, setTotalGraphData] = useState();
  const { data, isFetching, error } = useQuery(
    ["report-monthly-gallons-crosstab-all", selectedYear],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-monthly-gallons-crosstab-all/${selectedYear}`,
          { headers }
        );

        const monthlyDatasets = data.map((station, i) => {
          return {
            label: station.location_desc,
            data: months.map((month) => station[month]),
            backgroundColor: Object.values(lineColors)[i],
            units: "gal",
          };
        });
        setMonthlyGraphData({
          labels: monthlyLabels,
          datasets: monthlyDatasets,
        });

        const annualLabels = data.map((item) => item.location_desc);
        const annualDatasets = [
          {
            data: data.map((item) => item.total_af),
            backgroundColor: data.map((item, i) =>
              lighten(Object.values(lineColors)[i], 0.5)
            ),
            borderColor: data.map((item, i) => Object.values(lineColors)[i]),
            units: "af",
            borderWidth: 3,
            borderRadius: 5,
          },
        ];
        setAnnualGraphData({ labels: annualLabels, datasets: annualDatasets });

        const totalDatasets = [
          {
            data: [
              data
                .map((item) => item.total)
                .reduce((accum, next) => accum + next),
            ],
            backgroundColor: "#A2C4C9",
            units: "gal",
            borderWidth: 3,
            borderRadius: 5,
          },
        ];
        setTotalGraphData({ labels: ["Total"], datasets: totalDatasets });

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Loc",
      field: "location_desc",
    },
    {
      title: "Oct",
      field: "oct",
      render: (row) =>
        row.oct !== null ? Number(row.oct).toLocaleString() : "",
    },
    {
      title: "Nov",
      field: "nov",
      render: (row) =>
        row.nov !== null ? Number(row.nov).toLocaleString() : "",
    },
    {
      title: "Dec",
      field: "dec",
      render: (row) =>
        row.dec !== null ? Number(row.dec).toLocaleString() : "",
    },
    {
      title: "Jan",
      field: "jan",
      render: (row) =>
        row.jan !== null ? Number(row.jan).toLocaleString() : "",
    },
    {
      title: "Feb",
      field: "feb",
      render: (row) =>
        row.feb !== null ? Number(row.feb).toLocaleString() : "",
    },
    {
      title: "Mar",
      field: "mar",
      render: (row) =>
        row.mar !== null ? Number(row.mar).toLocaleString() : "",
    },
    {
      title: "Apr",
      field: "apr",
      render: (row) =>
        row.apr !== null ? Number(row.apr).toLocaleString() : "",
    },
    {
      title: "May",
      field: "may",
      render: (row) =>
        row.may !== null ? Number(row.may).toLocaleString() : "",
    },
    {
      title: "Jun",
      field: "jun",
      render: (row) =>
        row.jun !== null ? Number(row.jun).toLocaleString() : "",
    },
    {
      title: "Jul",
      field: "jul",
      render: (row) =>
        row.jul !== null ? Number(row.jul).toLocaleString() : "",
    },
    {
      title: "Aug",
      field: "aug",
      render: (row) =>
        row.aug !== null ? Number(row.aug).toLocaleString() : "",
    },
    {
      title: "Sep",
      field: "sep",
      render: (row) =>
        row.sep !== null ? Number(row.sep).toLocaleString() : "",
    },
    {
      title: "Tot",
      field: "total",
      render: (row) =>
        row.total !== null ? Number(row.total).toLocaleString() : "",
    },
    {
      title: "Tot (af)",
      field: "total_af",
      render: (row) =>
        row.total_af !== null ? Number(row.total_af).toLocaleString() : "",
    },
  ];

  return (
    <>
      <Helmet title="Monthly Historical Production" />
      <Typography variant="h3" gutterBottom display="inline">
        Monthly Historical Production Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Monthly Historical Production</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    toolbar={
                      Object.values(years).length > 0 && (
                        <FormControl
                          size="small"
                          variant="outlined"
                          style={{ width: "120px", marginRight: "8px" }}
                        >
                          <InputLabel id="year-select">Year</InputLabel>
                          <Select
                            label="Year"
                            name="years"
                            value={selectedYear}
                            onChange={(event) =>
                              setSelectedYear(event.target.value)
                            }
                          >
                            {years.map((year) => (
                              <MenuItem
                                key={year.report_year}
                                value={year.report_year}
                              >
                                {year.report_year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )
                    }
                    options={{ filtering: false }}
                    label={`Monthly Historical Production Report for ${selectedYear} (*Units in gallons unless otherwise noted)`}
                    isLoading={isFetching}
                    columns={tabColumns}
                    data={data}
                    height="1200px"
                    pageSize={30}
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                {selectedYear} Monthly Well Production
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TimeseriesContainer>
                  <Grid
                    container
                    pb={6}
                    mt={2}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Grid
                      item
                      style={{
                        flexGrow: 1,
                        maxWidth: "calc(100% - 54px)",
                      }}
                    />
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "6px",
                      }}
                    />
                    <Grid
                      item
                      style={{
                        width: "106px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SaveGraphButton
                        ref={saveRef}
                        title="Monthly Well Production"
                      />
                      <PrintGraphButton
                        ref={saveRef}
                        title="Monthly Well Production"
                      />
                    </Grid>
                  </Grid>
                  <GraphWrapper>
                    <Monthly
                      title={`${selectedYear} Monthly Well Production`}
                      data={monthlyGraphData}
                      error={error}
                      isLoading={isFetching}
                      yLabel="Monthly Gallons Pumped"
                      ref={saveRef}
                      footer={true}
                      label={true}
                      xLabel={`October ${selectedYear} - September ${
                        selectedYear + 1
                      }`}
                      // title="Daily Total Pumping vs Year to Date Pumping"
                    />
                  </GraphWrapper>
                </TimeseriesContainer>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                {selectedYear} Annual Well Production
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TimeseriesContainer>
                  <Grid
                    container
                    pb={6}
                    mt={2}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Grid
                      item
                      style={{
                        flexGrow: 1,
                        maxWidth: "calc(100% - 54px)",
                      }}
                    />
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "6px",
                      }}
                    />
                    <Grid
                      item
                      style={{
                        width: "106px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SaveGraphButton
                        ref={saveRef}
                        title="Annual Well Production"
                      />
                      <PrintGraphButton
                        ref={saveRef}
                        title="Annual Well Production"
                      />
                    </Grid>
                  </Grid>
                  <GraphWrapper>
                    <Monthly
                      title={`${selectedYear} Annual Well Production`}
                      data={annualGraphData}
                      error={error}
                      isLoading={isFetching}
                      ref={saveRef}
                      indexAxis="y"
                      stacked={false}
                      interactionMode="y"
                      displayLegend={false}
                      xLabel="Year-to-date Production (Acre-Feet) by Well"
                      // title="Daily Total Pumping vs Year to Date Pumping"
                    />
                  </GraphWrapper>
                </TimeseriesContainer>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                {selectedYear} Total to System Year-to-Date
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TimeseriesContainerSmall>
                  <Grid
                    container
                    pb={6}
                    mt={2}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Grid
                      item
                      style={{
                        flexGrow: 1,
                        maxWidth: "calc(100% - 54px)",
                      }}
                    />
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "6px",
                      }}
                    />
                    <Grid
                      item
                      style={{
                        width: "106px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <SaveGraphButton
                        ref={saveRef}
                        title="Total to System Year-to-Date"
                      />
                      <PrintGraphButton
                        ref={saveRef}
                        title="Total to System Year-to-Date"
                      />
                    </Grid>
                  </Grid>
                  <GraphWrapper>
                    <Monthly
                      title={`${selectedYear} Total to System Year-to-Date`}
                      data={totalGraphData}
                      error={error}
                      isLoading={isFetching}
                      ref={saveRef}
                      indexAxis="y"
                      stacked={false}
                      interactionMode="y"
                      displayLegend={false}
                      xLabel="Gallons to System (Including Change in Storage + CSU)"
                      // title="Daily Total Pumping vs Year to Date Pumping"
                    />
                  </GraphWrapper>
                </TimeseriesContainerSmall>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default MonthlyHistoricalProduction;
