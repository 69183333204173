import React, { forwardRef } from "react";
import { withTheme } from "styled-components/macro";

import { Chart, Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import Loader from "../../Loader";
import { Typography } from "@material-ui/core";
import { lineColors } from "../../../utils";

Chart.register(zoomPlugin);

const Monthly = forwardRef(
  (
    {
      data,
      error,
      isLoading = true,
      indexAxis = "x",
      yLabel = "",
      xLabel = "",
      theme,
      interactionMode = "index",
      stacked = true,
      displayLegend = true,
      footer = false,
      label = false,
      title = "",
    },
    ref
  ) => {
    const plugins = [
      {
        id: "chartFillBackground",
        beforeDraw: (chart) => {
          const ctx = chart.ctx;
          ctx.fillStyle = lineColors.lightGray;
          ctx.fillRect(0, 0, chart.width, chart.height);
        },
      },
      {
        id: "chartAreaBorder",
        beforeDraw(chart) {
          const {
            ctx,
            chartArea: { left, top, width, height },
          } = chart;
          if (chart.options.plugins.zoom.zoom.wheel.enabled) {
            ctx.save();
            ctx.strokeStyle = "#800000";
            ctx.lineWidth = 3;
            ctx.strokeRect(left, top, width, height);
            ctx.restore();
          }
        },
      },
      {
        id: "annotatedVerticalLine",
        afterDraw(chart) {
          if (chart.config.type === "line" && chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let yAxis = chart.scales.y;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 9;
            ctx.strokeStyle = "rgba(0, 0, 255, 0.2)";
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "rgba(0, 0, 255, 0.4)";
            ctx.stroke();
            ctx.restore();
          }
        },
      },
    ];

    const options = {
      indexAxis: indexAxis,
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: interactionMode,
      },
      plugins: {
        title: {
          display: true,
          text: title,
          // padding: {
          //   top: 10,
          //   bottom: 30,
          // },
        },
        filler: {
          propagate: false,
        },
        tooltip: {
          footerAlign: "center",
          callbacks: {
            footer: (tooltipItems) => {
              return (
                footer &&
                `Total Pumped: ${Number(
                  tooltipItems
                    .map((item) => item.raw)
                    .reduce((accum, next) => accum + next)
                ).toLocaleString()}`
              );
            },
            label: function (tooltipItems) {
              return `${
                label ? tooltipItems.dataset.label : tooltipItems.label
              }: ${tooltipItems.formattedValue} ${tooltipItems.dataset.units}`;
            },
          },
        },
        legend: {
          display: displayLegend,
          labels: {
            usePointStyle: true,
            color: lineColors.darkGray,
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy",
          },
          zoom: {
            mode: "xy",
            overScaleMode: "y",
            wheel: {
              enabled: false,
            },
            pinch: {
              enabled: true,
            },
          },
          //TODO line segment styling
        },
      },

      scales: {
        x: {
          stacked: stacked,
          grid: {
            display: false,
          },
          ticks: {
            color: lineColors.darkGray,
          },
          title: {
            display: true,
            text: xLabel,
            color: lineColors.darkGray,
          },
        },

        y: {
          stacked: stacked,
          // suggestedMax: 7,
          position: "left",
          display: true,
          title: {
            display: true,
            text: yLabel,
            color: lineColors.darkGray,
          },
          ticks: {
            color: lineColors.darkGray,
          },
          grid: {
            color: theme.palette.text.gridLines,
            borderDash: [5, 5],
            drawBorder: true,
            drawTicks: true,
          },
        },
      },
      onClick(e) {
        const chart = e.chart;
        chart.options.plugins.zoom.zoom.wheel.enabled =
          !chart.options.plugins.zoom.zoom.wheel.enabled;
        chart.update();
      },
    };

    if (error) return "An error has occurred: " + error.message;
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {data?.datasets?.length > 0 ? (
              <Bar
                plugins={plugins}
                ref={ref}
                data={data}
                options={options}
                type="bar"
              />
            ) : (
              <Typography>No Data Available</Typography>
            )}
          </>
        )}
      </>
    );
  }
);
export default withTheme(Monthly);
