/* eslint-disable import/first */
import React from "react";
import { CRUD_MODELS, ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Activity,
  Database,
  HardDrive,
  Home,
  List,
  Monitor,
  Users,
  Archive,
  Briefcase,
  FileText,
  Clipboard,
  Edit,
  Settings,
} from "react-feather";

import AdminGuard from "../components/AdminGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";

import Blank from "../pages/pages/Blank";
import Changelog from "../pages/docs/Changelog";
import Landing from "../pages/presentation/Landing";
import { DocumentationProvider } from "../pages/docs/DocumentationProvider";
import * as inflector from "inflected";

import Default from "../pages/dashboards/Default";
import { CrudProvider } from "../CrudProvider";

import DailyTotalPumpingVsAvgDailyPumpingRates from "../pages/dataAccess/timeseriesGraphs/DailyTotalPumpingVsAvgDailyPumpingRates";
import PlantFlows from "../pages/DataManagement/dataEntry/PlantFlows";
import SummaryDashboard from "../pages/dashboards/SummaryDashboard";
import MonthlyAugReport from "../pages/DataManagement/Accounting/MonthlyAugReport";
import WellProduction from "../pages/DataManagement/dataScrubbing/WellProduction";
import ReportWaterlevels from "../pages/dataAccess/reports/ReportWaterlevels";
import WaterDepthVsPumping from "../pages/dataAccess/timeseriesGraphs/WaterDepthVsPumping";
import AuthGuard from "../components/AuthGuard";
import UserGuard from "../components/UserGuard";
import UserVisibilityFilter from "../components/UserVisibilityFilter";
import MonthlyHistoricalProduction from "../pages/dataAccess/MonthlyHistoricalProduction";
import DailyHistoricalProduction from "../pages/dataAccess/DailyHistoricalProduction";
import DailyMeters from "../pages/dataAccess/DailyMeters";
import MonthlyMeters from "../pages/dataAccess/MonthlyMeters";

const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const CrudIndexPage = async(() => import("../components/crud/CrudIndexPage"));

const getSidebarMenu = (list) => {
  return list.map((item) => {
    const slug = inflector.dasherize(inflector.underscore(item.name));
    return {
      id: inflector.titleize(item.name),
      path: `/models/${slug}`,
      model: inflector.singularize(item.name),
      icon: item.icon || <Database />,
      component: CrudIndexPage,
      config: require(`../pages/models/${item.name}Config`),
      provider: CrudProvider,
      children: item.children,
      header: item.header,
      guard: item.guard,
      visibilityFilter: item.visibilityFilter,
    };
  });
};

// const getCrudRoutes = (list) => {
//   return list.map((item) => {
//     const config = require(`../pages/models/${item.name}Config`);
//     const slug = inflector.dasherize(inflector.underscore(item.name));
//
//     return {
//       id: inflector.titleize(item.name),
//       path: `/models/${slug}`,
//       model: inflector.singularize(item.name),
//       component: CrudIndexPage,
//       provider: CrudProvider,
//       config,
//       crud: [
//         {
//           path: `/models/${slug}/:id`,
//           name: `View ${inflector.titleize(inflector.singularize(item.name))}`,
//           component: CrudViewPage,
//           provider: CrudProvider,
//           model: inflector.singularize(item.name),
//           config,
//         },
//         {
//           path: `/models/${slug}/add`,
//           name: `Add ${inflector.titleize(inflector.singularize(item.name))}`,
//           component: CrudViewPage,
//           provider: CrudProvider,
//           model: inflector.singularize(item.name),
//           config,
//         },
//       ],
//     };
//   });
// };

const crudSidebarMenu = [...getSidebarMenu(CRUD_MODELS)];
// const modelCrudRoutes = [...getCrudRoutes(CRUD_MODELS)];

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
  guard: AuthGuard,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const mainRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <Home />,
  component: Default,
  children: null,
  containsHome: true,
  guard: AuthGuard,
};

const summaryDashboardRoute = {
  id: "Summary Dashboard",
  path: "/summary-dashboard",
  icon: <Clipboard />,
  component: SummaryDashboard,
  children: null,
  guard: UserGuard,
  visibilityFilter: UserVisibilityFilter,
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: process.env.REACT_APP_VERSION || "v1.0.0",
  icon: <List />,
  component: Changelog,
  provider: DocumentationProvider,
  children: null,
};

const dataManagement = {
  header: "Data Management",
  id: "Data Entry",
  icon: <Database />,
  children: [
    {
      path: "/data-management/data-entry/plant-flows",
      name: "Plant FLows",
      component: PlantFlows,
    },
  ],
  guard: UserGuard,
  visibilityFilter: UserVisibilityFilter,
};

const accountingRoute = {
  id: "Accounting",
  icon: <Edit />,
  children: [
    {
      path: "/data-management/accounting/monthly-aug-report",
      name: "Monthly Aug Report",
      component: MonthlyAugReport,
    },
  ],
  guard: UserGuard,
  visibilityFilter: UserVisibilityFilter,
};

const dataScrubbing = {
  id: "Data Scrubbing",
  icon: <HardDrive />,
  children: [
    {
      path: "/data-management/data-scrubbing/well-production",
      name: "Well Production",
      component: WellProduction,
    },
  ],
  guard: UserGuard,
  visibilityFilter: UserVisibilityFilter,
};

const timeseriesRoutes = {
  header: "Data Access",
  id: "Reports",
  icon: <FileText />,
  children: [
    {
      path: "/data-access/reports/water-levels",
      name: "Water Levels",
      component: ReportWaterlevels,
    },
  ],
  guard: AuthGuard,
};

const reportsRoutes = {
  id: "Time Series",
  icon: <Activity />,
  children: [
    {
      path: "/data-access/graphs/daily-pumping-vs-avg-pumping",
      name: "Daily Total Pumping vs Average Daily Pumping",
      component: DailyTotalPumpingVsAvgDailyPumpingRates,
    },
    {
      path: "/data-access/graphs/daily-water-depth-vs-pumping",
      name: "Water Depth vs Pumping",
      component: WaterDepthVsPumping,
    },
  ],
  guard: UserGuard,
  visibilityFilter: UserVisibilityFilter,
};

const historicalProductionRoute = {
  id: "Historical Production",
  icon: <Settings />,
  children: [
    {
      path: "/data-access/daily-production",
      name: "Daily Production",
      component: DailyHistoricalProduction,
    },
    {
      path: "/data-access/monthly-production",
      name: "Monthly Production",
      component: MonthlyHistoricalProduction,
    },
    {
      path: "/data-access/daily-meters",
      name: "Daily Meters",
      component: DailyMeters,
    },
    {
      path: "/data-access/monthly-meters",
      name: "Monthly Meters",
      component: MonthlyMeters,
    },
  ],
  guard: AuthGuard,
};

const publicFilesRoutes = {
  id: "Public Files",
  header: "Documents",
  icon: <Archive />,
  path: "/data-access/documents/public-files",
  name: "Public Files",
  component: Blank,
  guard: AuthGuard,
};
//
// const clientDocsRoutes = {
//   id: "Client Docs",
//   icon: <Folder />,
//   path: "/data-access/documents/client-docs",
//   name: "Client Documents",
//   component: ClientDocs,
//   guard: UserGuard,
//   visibilityFilter: UserVisibilityFilter,
// };

const adminDocsRoutes = {
  id: "Admin Docs",
  icon: <Briefcase />,
  path: "/data-access/documents/admin-docs",
  name: "Admin Documents",
  component: Blank,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  mainRoutes,
  summaryDashboardRoute,
  changelogRoutes,
  dataManagement,
  accountingRoute,
  dataScrubbing,
  timeseriesRoutes,
  reportsRoutes,
  historicalProductionRoute,
  publicFilesRoutes,
  // clientDocsRoutes,
  adminDocsRoutes,
  accountRoutes,
];

export const dashboardMaxContentLayoutRoutes = [
  ...crudSidebarMenu,
  // ...modelCrudRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  mainRoutes,
  summaryDashboardRoute,
  dataManagement,
  accountingRoute,
  dataScrubbing,
  timeseriesRoutes,
  reportsRoutes,
  historicalProductionRoute,
  publicFilesRoutes,
  adminDocsRoutes,
  // ...crudSidebarMenu,
];
