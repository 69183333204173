import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useAuth0 } from "@auth0/auth0-react";

const Spacer = styled.div(spacing);

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px - 48px - 106px - 48px - 70px);
  position: relative;
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  opacity: 0.75;D
`;

const Divider = styled(MuiDivider)(spacing);

const SummaryDashboard = () => {
  const { user } = useAuth0();

  return (
    <>
      <Helmet title="Summary Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Summary Dashboard
          </Typography>
          <Typography variant="subtitle1">
            Welcome back, {user?.name}!
          </Typography>
        </Grid>

        <Grid item />
      </Grid>

      <Divider my={6} />

      <Container>
        <iframe
          src="https://docs.google.com/spreadsheets/d/1Yk0_fpvHoK6i0nvLB-xPFuKP-Wn_UDWVGUlMmqz11_o/pub?gid=608719206&amp;range=Graphs&amp;single=true&amp;headers=false&amp;chrome=false"
          width="100%"
          height="760px"
          frameBorder="1"
          scrolling="no"
          title="Summary Dashboard"
        />
        <Spacer mt={4} />
        <Subtitle variant="h5" gutterBottom component="span">
          Access the full workbook:
        </Subtitle>

        <Button
          style={{ marginLeft: "10px" }}
          size="small"
          color="primary"
          variant="contained"
          component="a"
          target="_blank"
          href="https://docs.google.com/spreadsheets/d/1Yk0_fpvHoK6i0nvLB-xPFuKP-Wn_UDWVGUlMmqz11_o/edit#gid=113012539"
        >
          Workbook
        </Button>
      </Container>
    </>
  );
};

export default SummaryDashboard;
