import React, { useState } from "react";
import { useQuery } from "react-query";

import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";

import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Panel from "../../components/panels/Panel";
import Table from "../../components/Table";
import useFetchData from "../../hooks/useFetchData";

const TableWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  // max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  // max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const MonthlyMeters = () => {
  const { getAccessTokenSilently } = useAuth0();

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [years] = useFetchData("report-available-years-lookup", [], true);

  const { data, isFetching, error } = useQuery(
    ["report-monthly-meters-crosstab-all", selectedYear],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-monthly-meters-crosstab-all/${selectedYear}`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Loc",
      field: "location_desc",
    },
    {
      title: "Oct",
      field: "oct",
      render: (row) =>
        row.oct !== null ? Number(row.oct).toLocaleString() : "",
    },
    {
      title: "Nov",
      field: "nov",
      render: (row) =>
        row.nov !== null ? Number(row.nov).toLocaleString() : "",
    },
    {
      title: "Dec",
      field: "dec",
      render: (row) =>
        row.dec !== null ? Number(row.dec).toLocaleString() : "",
    },
    {
      title: "Jan",
      field: "jan",
      render: (row) => (row.jan ? Number(row.jan).toLocaleString() : ""),
    },
    {
      title: "Feb",
      field: "feb",
      render: (row) =>
        row.feb !== null ? Number(row.feb).toLocaleString() : "",
    },
    {
      title: "Mar",
      field: "mar",
      render: (row) =>
        row.mar !== null ? Number(row.mar).toLocaleString() : "",
    },
    {
      title: "Apr",
      field: "apr",
      render: (row) =>
        row.apr !== null ? Number(row.apr).toLocaleString() : "",
    },
    {
      title: "May",
      field: "may",
      render: (row) =>
        row.may !== null ? Number(row.may).toLocaleString() : "",
    },
    {
      title: "Jun",
      field: "jun",
      render: (row) =>
        row.jun !== null ? Number(row.jun).toLocaleString() : "",
    },
    {
      title: "Jul",
      field: "jul",
      render: (row) =>
        row.jul !== null ? Number(row.jul).toLocaleString() : "",
    },
    {
      title: "Aug",
      field: "aug",
      render: (row) =>
        row.aug !== null ? Number(row.aug).toLocaleString() : "",
    },
    {
      title: "Sep",
      field: "sep",
      render: (row) =>
        row.sep !== null ? Number(row.sep).toLocaleString() : "",
    },
    {
      title: "Tot",
      field: "total",
      render: (row) =>
        row.total !== null ? Number(row.total).toLocaleString() : "",
    },
    {
      title: "Tot (af)",
      field: "total_af",
      render: (row) =>
        row.total_af !== null ? Number(row.total_af).toLocaleString() : "",
    },
  ];

  return (
    <>
      <Helmet title="Monthly Meters" />
      <Typography variant="h3" gutterBottom display="inline">
        Monthly Meters Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Monthly Meters</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    toolbar={
                      Object.values(years).length > 0 && (
                        <FormControl
                          size="small"
                          variant="outlined"
                          style={{ width: "120px", marginRight: "8px" }}
                        >
                          <InputLabel id="year-select">Year</InputLabel>
                          <Select
                            label="Year"
                            name="years"
                            value={selectedYear}
                            onChange={(event) =>
                              setSelectedYear(event.target.value)
                            }
                          >
                            {years.map((year) => (
                              <MenuItem
                                key={year.report_year}
                                value={year.report_year}
                              >
                                {year.report_year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )
                    }
                    options={{ filtering: false }}
                    label={`Monthly Meters Report for ${selectedYear} (*Units in meters unless otherwise noted)`}
                    isLoading={isFetching}
                    columns={tabColumns}
                    data={data}
                    height="1200px"
                    pageSize={30}
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default MonthlyMeters;
